<template>
	<div>
		<div class="row">
			<div class="col-12" v-if="form_message">
				<ErrorAlert :messageI18n="form_message" />
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<!-- Qualification -->
				<div class="form-group">
					<label>{{ $t('qualification.qualification') }}</label>
					<e-select
						v-model="form.qualification"
						:options="qualifications"
						track-by="qualification_id"
						label="qualification_libelle"
						:searchable="true"
						:allow-empty="true"
						:multiple="true"
						:sortable="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option }}</template>
						<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.qualification_libelle }}</span></template>
					</e-select>
				</div>

				<!-- Questions -->
				<QuestionForm
					ref="QuestionForm"
					v-for="qualification in form.qualification"
					:key="qualification.qualification_id"
					question_type="qualification"
					:question_fk="qualification.qualification_id"
					reponse_type="qualification_link"
					:reponse_fk="form.qualificationlink_id"
				/>
			</div>
		</div>

		<b-button
			variant="primary"
			@click="submitForm"
			:disabled="processing"
		>
			<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
			{{ $t('global.enregistrer') }}
		</b-button>
	</div>
</template>

<script type="text/javascript">
    import Qualification from '@/mixins/Qualification'
	import Shutter from '@/mixins/Shutter'
	import QualifShutters from '@/mixins/shutters-manager/Qualif'

	export default {
		name: 'QualificationFicheEditor',
		mixins: [Qualification, Shutter, QualifShutters],

		props: {
			qualificationlink_qualification: Number,
			qualificationlink_type: String,
			qualificationlink_fk: [String, Number],
			refresh_table: { type: Boolean, default: false }
		},

		data: () => ({
			processing: false,
			form_message: null,
			qualifications: [],
			form: {
				qualification: [],
				qualificationlink_id: 0
			},
			qualification_ids: [],
			qualification_link: null, // Chargé lorsque le qualificationlink_qualification/type/link change
		}),

		created() {
			this.init_component()
		},

		methods: {
			async init_component() {
				const type = await this.getQualificationType(this.qualificationlink_type)
				this.qualifications = await this.getQualifications(type.qualificationtype_id)
				if(this.qualificationlink_qualification) {
					this.reloadQualificationLink()
				}
			},

			async reloadQualificationLink() {
				// On va récupérer le link complet
				this.qualification_link = await this.getQualificationLink(
					this.qualificationlink_qualification,
					this.qualificationlink_type,
					this.qualificationlink_fk
				)

				// On met à jour le formulaire
				this.form.qualification.push(this.qualification_link.qualificationlink_qualification)
				this.form.qualificationlink_id = this.qualification_link.qualificationlink_id
			},

			async submitForm() {
				if(!this.form.qualification){
					return false
				}

				this.processing = true

				for(let i in this.form.qualification) {
                	const reponses = await this.$refs.QuestionForm[i].prepareReponses()
                	await this.saveQualificationFiche(this.form.qualification[i].qualification_id, this.qualificationlink_fk, this.qualificationlink_type, reponses, this.form.qualificationlink_id)
				}

                this.processing = false

                this.ok()

                this.shutterPanel().close('qualification-fiche-edit')
			},

			async addParent(qualif) {
				if(qualif) {
					const parent = await this.getQualificationById(qualif)
					
					if(!this.qualification_ids.includes(parent[0].qualification_id)) {
	                	this.form.qualification.push(parent[0])
						this.qualification_ids.push(parent[0].qualification_id)
					}
					await this.addParent(parent[0].qualification_parent)
	            }
			}
		},

		watch: {
			'form.qualification' (newVal, oldVal){
				if(newVal.length > oldVal.length) {
					const new_qualif = newVal.filter(x => !oldVal.includes(x)).concat(oldVal.filter(x => !newVal.includes(x)))[0];
					this.qualification_ids.push(new_qualif.qualification_id)
	                this.addParent(new_qualif.qualification_parent)
	            }
	            else if(newVal.length < oldVal.length) {
	            	const old_qualif = oldVal.filter(x => !newVal.includes(x)).concat(newVal.filter(x => !oldVal.includes(x)))[0];
					const index = this.qualification_ids.indexOf(old_qualif.qualification_id);
					if (index > -1) {
					 	this.qualification_ids.splice(index, 1);
					}
	            }
			}
		},

		components: {
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            QuestionForm: () => import('@/components/Questions/QuestionForm'),
		}
	}
</script>
